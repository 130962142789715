<template>
    <ideo-form-input type="time" :name="name" v-model="localeTimeValue" />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { DateTime } from 'luxon';

interface Props
{
    modelValue: string | null
    name: string
}

defineOptions({
    name: 'ideo-time',
    inheritAttrs: false
});

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  "modelValue": { default: null },
  "name": { default: '' }
});

const localeTimeValue = computed<string | null>({
    get: (): string =>
    {
        return convertDate(props.modelValue, 'locale');
    },
    set: (value: string) =>
    {
        emit('update:modelValue', convertDate(value, 'utc'));
    }
});

function convertDate(value: string, toFormat: 'locale' | 'utc'): string | null
{
    if (value)
    {
        const [hours, minutes] = value.split(':');
        const now = DateTime.local();

        if (toFormat === 'utc')
        {
            const localTime = DateTime.local(now.year, now.month, now.day, Number(hours), Number(minutes));

            return localTime.toUTC().toFormat('HH:mm');
        }
        else if (toFormat === 'locale')
        {
            const utcTime = DateTime.fromObject({ hour: Number(hours), minute: Number(minutes)}, { zone: 'utc' } );

            return utcTime.setZone('local').toFormat('HH:mm');
        }
    }

    return null;
}
</script>

<style lang="scss" scoped>
</style>
