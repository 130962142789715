import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Resource as FileResource } from '@/modules/core/files/services/StorageService';
import { Pagination, Resource, Statement } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';
import { FromToLocalizationModel } from '../../configuration/localizationZones/services/LocalizationsService';
import { FromToCarrierModel } from '../../configuration/carriers/service/CarriersService';
import { ProductsGroupsAutocompleteModel } from './ProductsGroupsService';

/**
 * ProductsService
 */
export default class ProductsService
{
    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/products', {
            params: merge({}, filter, pager.data(), {productsPublicIds: [...filter.productsPublicIds]})
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: string): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/products/${id}`)).data;
    }

    /**
     * @param id string
     *
     * @returns Promise<Resource<Product>>
     */
    public static async getShortInfo(id: string): Promise<Resource<Product>>
    {
        return (await axios.get<Resource<Product>>(`admin/products/${id}/shortinfo`)).data;
    }

    /**
     * @param id number
     * @param model FormModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async update(id: string, model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.put<Resource<FormModel>>(`admin/products/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<string[]>
     */
    public static async getMeasureunits(productPublicId: string = ''): Promise<ProductsMeasureUnitsShortInfo[]>
    {
        return (await axios.get<ProductsMeasureUnitsShortInfo[]>(`admin/products/measureunits`, {
            params : {
                productPublicId
            }})).data;
    }

    /**
     * @param filter ErpStatesListFilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ErpStatesListItemModel>>>
     */
    public static async getErpStatesList(filter: ErpStatesListFilterModel, pager: Pager): Promise<Pagination<Resource<ErpStatesListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ErpStatesListItemModel>>>('admin/products/erpstates', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param filter ErpStatesListFilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ErpStatesListItemModel>>>
     */
    public static async getProductsLocalizations(filter: any, pager: Pager): Promise<Pagination<Resource<any>>>
    {
        return (await axios.get<Pagination<Resource<any>>>('admin/productslocalizations', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param filter ErpStatesListFilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ErpStatesListItemModel>>>
     */
    public static async getProductsLocalizationsForCarrier(carrierId: string): Promise<Resource<FromToProductLocalizationModel>[]>
    {
        return (await axios.get<Resource<CarrierProductLocalizationsModel>>(`admin/productslocalizations/carrier/${carrierId}`)).data;
    }

    /**
     * @param filter InformatorListFilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<InformatorItemModel>>>
     */
    public static async getInformator(filter: InformatorItemFilterModel, pager: Pager): Promise<Pagination<Resource<InformatorItemModel>>>
    {
        return (await axios.get<Pagination<Resource<InformatorItemModel>>>('admin/products/informator', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param id string
     * @param model DimensionsFormModel
     *
     * @returns Promise<Statement>
     */
    public static async updateDimensions(id: string, model: DimensionsFormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/products/${id}/updatedimensions`, model)).data;
    }

    /**
     * @param id string
     * @param model WeightFormModel
     *
     * @returns Promise<Statement>
     */
    public static async updateWeight(id: string, model: WeightFormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/products/${id}/updateweight`, model)).data;
    }

    /**
     * @param id string
     *
     * @returns Promise<Resource<EanCodeProductItemModel>>
     */
    public static async findProductByEanCode(documentPublicId: string, ean: string): Promise<Resource<EanCodeProductItemModel>>
    {
        return (await axios.get<Resource<EanCodeProductItemModel>>(`admin/products/findbyean/${ean}`, {
            params: {
                documentPublicId: documentPublicId
            }
        })).data;
    }

    public static async findProductByProperty(documentPublicId: string, scannedValue: string): Promise<Resource<EanCodeProductItemModel>>
    {
        return (await axios.get<Resource<EanCodeProductItemModel>>(`admin/products/findbyproperty`, {
            params: {
                documentPublicId: documentPublicId,
                scannedValue: scannedValue
            }
        })).data;
    }

    /**
     * @param phrase string
     *
     * @returns Promise<Resource<ProductsAutocompleteModel>>
     */
    public static async findProductByPhrase(phrase: string): Promise<ProductsAutocompleteModel>
    {
        return (await axios.get<ProductsAutocompleteModel>(`admin/products/findbyphrase/${phrase}`)).data;
    }

    public static async getAutocompleteList(search: string, groupPublicId: string = null, limit: number = 10, onlyActive: boolean = true): Promise<ProductsAutocompleteModel[]>
    {
        const { data } = await axios.get<ProductsAutocompleteModel[]>('admin/products/autocomplete', {
            params: {
                search: search,
                limit: limit,
                groupPublicId: groupPublicId,
                onlyActive: onlyActive
            }
        });

        return data;
    }

    public static async fetchAutocompleteModel(id: string): Promise<ProductsAutocompleteModel>
    {
        const { data } = await axios.get<ProductsAutocompleteModel>(`admin/products/autocomplete/${id}`);

        return data;
    }

    public static async getDeliveryInfos(productId: string, pager: Pager): Promise<Pagination<Resource<NextDeliveryInfoModel>>>
    {
        return (await axios.get<Pagination<Resource<NextDeliveryInfoModel>>>(`admin/products/${productId}/next-delivery-infos`, {
            params: merge({}, pager.data())
        })).data;
    }

    public static async getProductGroupsAutocompleteList(search: string, limit: number = 10): Promise<ProductsAutocompleteModel[]>
    {
        const { data } = await axios.get<ProductsAutocompleteModel[]>('admin/products/groups/autocomplete', {
            params: {
                search: search,
                limit: limit
            }
        });

        return data;
    }

    public static async fetchProductGroupAutocompleteModel(id: string): Promise<ProductsAutocompleteModel>
    {
        const { data } = await axios.get<ProductsAutocompleteModel>(`admin/products/groups/autocomplete/${id}`);

        return data;
    }
}

export interface ListItemModel
{
    publicId: string
    name: string
    erpState: number
    wmsState: number
    baseMeasureUnit: string
    nextDeliveryInfos: NextDeliveryInfoModel[],
    toIssue: number,
    haveMoreDeliveryInfos: boolean
}

export interface FilterModel
{
    searchPhrase: string;
    deliveryDate: {
        from: DateTime,
        to: DateTime
    };
    isOrdered: boolean;
    productsPublicIds: string[];
    measureUnit: string;
    noneZeroState: {
        WMSStates: boolean,
        ERPStates: boolean
    };
    showWithReservations: boolean;
    localizationCode: string;
    isActive: boolean;
}
export interface FormModel
{
    publicId: string;
    symbol: string;
    name: string;
    ean: string;
    weight: WeightFormModel;
    dimensions: DimensionsFormModel;
    images: FileResource[];
    files: FileResource[];
    group: ProductsGroupsAutocompleteModel;
    ownFields: any[];
    isDangerousGood: boolean
}
export interface ErpStatesListItemModel
{
    publicId: string;
    warehousePublicId: string;
    warehouseName: string;
    currentValue: QuantityModel;
    reserved: QuantityModel;
    available: QuantityModel;
}
export interface ErpStatesListFilterModel
{
    productPublicId: string;
}

export interface InformatorItemModel
{
    documentNumber: string,
    quantity: number,
    deliveryDateUtc: DateTime
}
export interface InformatorItemFilterModel
{
    productPublicId: string;
    InformatorType: InformatorType
}

export enum InformatorType {
    Receipts = 'Receipts',
    Issues = 'Issues',
    Transfers = 'Transfers',
    Other = 'Other'
}

export interface WeightFormModel {
    unit: string
    weight: number
    pattern: string
    weightAsString: string
    weightInKg: number
    precision: number
}
export interface DimensionsFormModel {
    widthInMeter: number
    lengthInMeter: number
    heightInMeter: number
    volumeInMeter: number
    volumeInMeterAsString: string
    dimensionsInMeterAsString: string
    width: number
    length: number
    height: number
    volume: number
    volumeAsString: string
    dimensionsAsString: string
    dimensionUnit: string
    dimensionCubicUnit: string
    precision: number
    dimensionsString: string
}

export interface QuantityModel
{
    measureUnit: string;
    quantity: number;
    quantityAsString: string;
    quantityAsStringWithUnit: string;
}
export interface EanCodeProductItemModel
{
    findedByAdditionalBarcode: boolean
    additionalBarcodeQuantity: number
    additionalBarcode: string
    publicId: string
    name: string
    symbol: string
    ean: string
    imageUrl: string
    weight: WeightFormModel
    dimensions: DimensionsFormModel
    measureUnit: string
    productScannedQuantity: number
    productToScanQuantity: number
}

export interface ProductsAutocompleteModel
{
    publicId: string
    name: string
}
export interface Product {
    publicId: string
    name: string
    symbol: string
    ean: string
    imageUrl: string
    weight: WeightFormModel
    dimensions: DimensionsFormModel
}

export interface FromToProductLocalizationModel
{
    product: Product
    localization: FromToLocalizationModel
    carrier: FromToCarrierModel
    batch: any
    quantity: number
}

export interface CarrierProductLocalizationsModel
{
    carrier: FromToCarrierModel
    localizations: FromToProductLocalizationModel[]
}

export interface ProductsMeasureUnitsShortInfo {
    isBase: boolean;
    measureUnit: string;
}

export interface NextDeliveryInfoModel {
    documentNumber: string;
    deliveryDateUtc: string;
    quantity: QuantityModel;
}
