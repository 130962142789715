import { ref } from 'vue';
import { defineStore } from 'pinia';
import PersonalCollectingPanelService, { ConfigModel } from '@/modules/wms/issue/personal-collecting-panel/services/PersonalCollectingPanelService';

export const usePersonalCollectingPanelStore = defineStore('personal-collecting-panel', () =>
{
    const config = ref<ConfigModel>({
        isActive: false,
        columns: {
            documentNumber: false,
            status: false,
            clientNumber: false,
            percentageProgress: false,
            documentIssueDateTimeUtc: false,
            eta: false,
        }
    });

    const loadConfig = async (): Promise<void> =>
    {
        try
        {
            const response = await PersonalCollectingPanelService.getConfig();

            config.value = response;
        }
        catch (ex: any)
        {
            // @ts-ignore
            console.error(ex);
        }
    };

    return {
        config,
        loadConfig
    };
});
