import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import { Resource, Statement } from '@/helpers/Interfaces';

/**
 * PersonalCollectingPanelService
 */
export default class PersonalCollectingPanelService
{
    /**
     *
     * @returns Promise<ConfigModel>
     */
    public static async getConfig(): Promise<ConfigModel>
    {
        return (await axios.get<ConfigModel>(`admin/issue/personal-collecting/panel/configuration`)).data;
    }

    /**
     *
     * @returns Promise<Resource<ListItemModel>[]>
     */
    public static async getOrdersList(): Promise<Resource<ListItemModel>[]>
    {
        return (await axios.get<Resource<ListItemModel>[]>(`admin/issue/personal-collecting/panel/orders`)).data;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<Statement>
     */
    public static async setTime(publicId: string, filter: SetTimeModel): Promise<Statement>
    {
        return (await axios.get<Statement>(`admin/issue/personal-collecting/panel/orders/${publicId}/set-time`, {
            params: merge({}, filter)
        })).data;
    }
}

export interface ConfigModel {
    isActive: boolean
    columns: {
        documentNumber: boolean
        status: boolean
        clientNumber: boolean
        percentageProgress: boolean
        documentIssueDateTimeUtc: boolean
        eta: boolean
    }
}

export interface ListItemModel {
    publicId: string
    documentNumber: string
    status: string
    clientNumber: string
    percentageProgress: number
    documentIssueDateTimeUtc: string
    eta: string
}

export interface SetTimeModel {
    time: string
    isFinished: boolean
}
