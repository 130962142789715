<script lang="ts" setup>
import { computed, nextTick, onMounted, ref, watch, type ComputedRef} from 'vue';
import { handleException } from '@/helpers/Utils';
import { useAlerts } from '@/plugins/alerts';
import { useLogging } from '@/plugins/logging';
import { useLocalization } from '@/plugins/localization';
import IssueServices, { CollectingSplitConfigModel, CollectingSplitConfigPropertyModel } from '@/modules/wms/issue/common/services/IssueServices';
import { KeyValue } from '@/helpers/Interfaces';

interface AdditionalConfiguration {
    autocompleteType: string;
    type: string;
    value: string | null
    visibility?: string[]
    extraData: any
    fields: AdditionalConfiguration[]
    name: string
    setToField: string
}

interface Item {
    additionalConfiguration?: AdditionalConfiguration
    name: string;
    type: string;
    value: string | any[];
    values: any[];
    hidden: boolean,
    fields: AdditionalConfiguration[]
    setToField: string
}

interface SplitGroupDbModel {
    name: string;
    priority: string;
    coordinates: SplitCoordinationDbModel[];
}

interface SplitCoordinationDbModel {
    type: string;
    values: Record<string, string>;
}

interface Props {
    item: Item;
    mainItem: Item;
}

const { $alert } = useAlerts();
const { $log } = useLogging();
const { $t } = useLocalization();

const emit = defineEmits(["update"]);

const props = defineProps({
  "item": null,
  "mainItem": null
});
const computedItem: ComputedRef<Item> = computed(() =>
{
    return props.item;
});

const mainItem: ComputedRef<Item> = computed(() =>
{
    return props.mainItem;
});

const collectingSplitOptions = ref<CollectingSplitConfigModel[]>([]);

onMounted(() =>
{
    loadCollectingConfig();
});

const isFieldVisible = (field: AdditionalConfiguration): boolean =>
{
    if (!field.visibility || !field.visibility?.length) return false;

    return field.visibility.includes(mainItem.value.value?.toString());
};

const add = () : void  =>
{
    const object: any = {
        id: Date.now().toString(36) + Math.random().toString(36).substring(2, 12).padStart(12, "0"),
        priority: null,
        coordinates: []
    };

    const visibleFields = computedItem.value.fields.filter(field => isFieldVisible(field));

    for (let index = 0; index < visibleFields.length; index++)
    {
        const field = visibleFields[index];

        if (!field.value)
            return;

        object[field.setToField] = field.value;

        field.value = null;
    }

    if (!computedItem.value.value)
        computedItem.value.value = [];

    computedItem.value.value = [ ...computedItem.value.value, object];
};


const getObjectNameByKey = (key: string) : string =>
{
    const field = computedItem.value.fields.filter(field => isFieldVisible(field)).find(x => x.setToField == key);

    if (field)
        return field.name;

    return '';
};

async function loadCollectingConfig()
{
    try
    {
        const response = await IssueServices.getCollectingSplitConfig();

        collectingSplitOptions.value = response;
    }
    catch (ex)
    {
        collectingSplitOptions.value = [];
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}

const priorityOptions = ref(Array.from({ length: 20 }, (_, index) =>
{
    let sufix = '';

    if (index == 0)
    {
        sufix = ' - Najwyższy';
    }
    else if (index == 19)
    {
        sufix = ' - Najniższy';
    }

    return {
        key: `${index + 1}${sufix}`,
        value: index
    } as KeyValue<string, number>;
}));
const usedPriorities = computed(() => (computedItem.value.value as any[]).map((el: any) => el.priority));

const unusedPriorities = (pickedValue: number) =>
{
    return priorityOptions.value.filter((num: KeyValue<string, number>) => !usedPriorities.value.includes(num.value) || num.value == pickedValue);
};

const getCollectingConfigTypeFields = (typeName: string): any[] =>
{
    const type = collectingSplitOptions.value.find((el: CollectingSplitConfigModel) => el.type == typeName);

    return type?.properties ?? [];
};

const getCollectingConfigFieldComponent = (componentType: CollectingSplitConfigPropertyModel): string =>
{
    switch (componentType.type)
    {
        case "autocomplete-courier":
            return "CouriersPicker";
        case "autocomplete-product-feature":
            return "ProductsFeaturesPicker";
        case "autocomplete-product-group":
            return  "ProductGroupsPicker";
        case "autocomplete-zone":
            return "ZonesPicker";
        case 'Decimal':
            return 'ideo-form-input';
        default:
            return 'ideo-form-input';
    }
};

const onOrderPartitionSelect = (index: number) =>
{
    nextTick(() =>
    {
        computedItem.value.value[index].values = {};
    });
};

const addCoordination = (value: any) =>
{
    const coordination: SplitCoordinationDbModel = {
        type: '',
        values: {}
    };

    value.coordinates = [ ...value.coordinates, coordination];
};

const removeCoordination = (valueIndex: number, coordinateIndex: number) =>
{
    (computedItem.value.value as any[])[valueIndex].coordinates.splice(coordinateIndex, 1);
};

watch(() => props.item, () =>
{
    emit('update');
}, {deep: true});

const remove = (index: number) : void =>
{
    (computedItem.value.value as any[]).splice(index, 1);
};
</script>
<template>
    <div class="p-2 bg-light">
        <div v-if="computedItem.value" class="mt-3">
            <template v-for="(value, index) in computedItem.value" :key="index">
                <div class="d-flex align-items-start">
                    <template v-for="field in computedItem.fields" :key="field.name">
                        <template v-if="isFieldVisible(field)">
                            <ideo-form-group :label="field.name" class="me-2 min-width-150">
                                <ideo-form-input v-model="value[field.setToField]" :name="getObjectNameByKey(field.setToField)" />
                            </ideo-form-group>
                            <ideo-form-group style="width: 200px" :label="$t('[[[Priorytet kolejki]]]')" class="me-2">
                                <ideo-form-select v-model="value['priority']">
                                    <ideo-form-select-option v-for="option in unusedPriorities(value['priority'])" :value="option.value" :key="option">{{ option.key }}</ideo-form-select-option>
                                </ideo-form-select>
                            </ideo-form-group>
                            <div class="d-flex flex-column mt-auto">
                                <div class="d-flex" v-for="(coordination, coordinatesIndex) in value.coordinates" :key="coordinatesIndex">
                                    <ideo-form-group :label="$t('[[[Typ]]]')" class="me-2" style="width: 200px">
                                        <ideo-multiselect
                                            v-model="coordination.type" :options="collectingSplitOptions" track-by="type" label="name" @select="onOrderPartitionSelect(index)"
                                            name="type" :searchable="false" :show-reset="false" :disabled="false"
                                        />
                                    </ideo-form-group>
                                    <template v-if="getCollectingConfigTypeFields(coordination.type).length">
                                        <ideo-form-group v-for="configField in getCollectingConfigTypeFields(coordination.type)" style="width: 200px" :label="configField.name" :key="configField.key" class="me-2">
                                            <component
                                                v-if="configField.type.includes('autocomplete')"
                                                :is="getCollectingConfigFieldComponent(configField)" v-model="coordination.values[configField.key]"
                                                :name="configField.key" :searchable="true" :show-reset="false" :disabled="false" :refresh="true"
                                            />
                                            <component
                                                v-else-if="configField.type.includes('Decimal')"
                                                :is="getCollectingConfigFieldComponent(configField)" v-model="coordination.values[configField.key]"
                                                :name="configField.key" min="0" type="number"
                                            />
                                            <component
                                                v-else
                                                :is="getCollectingConfigFieldComponent(configField)" v-model="coordination.values[configField.key]"
                                                :name="configField.key"
                                            />
                                        </ideo-form-group>
                                    </template>
                                    <ideo-form-group label="" class="mt-auto">
                                        <ideo-button variant="danger" :title="$t('[[[Usuń warunek]]]')" @click="removeCoordination(index, coordinatesIndex)" class="mb-auto mt-auto normal-button">
                                            <i class="fa-solid fa-trash"></i>
                                        </ideo-button>
                                    </ideo-form-group>
                                </div>
                                <div class="d-flex">
                                    <ideo-form-group label="" class="mt-auto me-2" style="width: 200px">
                                        <ideo-button variant="success" :title="$t('[[[Dodaj warunek]]]')" @click="addCoordination(value)" class="normal-button w-100">
                                            <span>{{ $t('[[[Dodaj warunek]]]') }}</span><i class="fa-solid fa-plus ms-2"></i>
                                        </ideo-button>
                                    </ideo-form-group>
                                    <ideo-form-group label="" style="width: 200px">
                                        <ideo-button variant="danger" :title="$t('[[[Usuń kolejkę]]]')" @click="remove(index)" class="normal-button w-100">
                                            <span>{{ $t('[[[Usuń kolejkę]]]') }}</span><i class="fa-solid fa-minus ms-2"></i>
                                        </ideo-button>
                                    </ideo-form-group>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
                <hr>
            </template>
        </div>
        <template v-if="computedItem.fields">
            <div class="d-flex align-items-center">
                <template v-for="field in computedItem.fields" :key="field.id">
                    <template v-if="isFieldVisible(field)">
                        <ideo-form-group :label="field.name" class="me-2 min-width-150">
                            <ideo-form-input v-model="field.value" :name="getObjectNameByKey(field.setToField)" />
                        </ideo-form-group>
                    </template>
                </template>
                <ideo-form-group class="mt-auto mb-3">
                    <ideo-button variant="success" :title="$t('[[[Dodaj]]]')" class="normal-button" @click="add()">
                        <i class="fa-solid fa-plus"></i>
                    </ideo-button>
                </ideo-form-group>
            </div>
        </template>
    </div>
</template>


<style lang="scss" scoped>
.min-width-150{
    min-width: 150px;
}
</style>
