<template>
    <div :class="{'action-bar d-flex px-3': condition && (back().length > 0 || toolbar().length > 0)}">
        <slot name="default" v-if="!condition"></slot>
        <div class="flex-fill d-flex align-items-center" v-if="condition">
            <portal-target name="navbar-toggler-button" v-if="condition && (back().length > 0 || toolbar().length > 0)" />
            <div v-if="back().length > 0">
                <vnodes :vnodes="firstBack()" />
            </div>
            <div class="flex-fill">
                <action-header :auto-header="autoHeader" :header-title="headerTitle" :header-icon="headerIcon" />
            </div>
            <div>
                <vnodes :vnodes="firstToolbar()" v-if="toolbar().length == 1" />
                <div class="dropdown btn-group d-block" :class="{'show': dropdown}" v-if="toolbar().length > 1">
                    <vnodes :vnodes="firstToolbar()" />
                    <button type="button" class="dropdown-toggle dropdown-toggle-split" :class="firstToolbarClass()" @click="dropdown = !dropdown"></button>
                    <div class="dropdown-menu dropdown-menu-right" :class="{'show': dropdown}">
                        <vnodes :vnodes="otherToolbar()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="floating rounded-pill shadow" v-if="condition && other().length == 1">
            <vnodes :vnodes="other()" />
        </div>
        <div class="floating rounded-pill shadow btn-group dropup" :class="{'show': floating}" v-if="condition && other().length > 1">
            <action-button icon="fas fa-ellipsis-v" variant="dark" @click="floating = !floating" />
            <div class="dropdown-menu dropdown-menu-right" :class="{'show': floating}">
                <vnodes :vnodes="other()" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from '@/helpers/Decorators';

@Options({
    name: 'action-bar',
    components: {
        'vnodes': (props: Record<string, any>, context: any) => props.vnodes
    }
})
export default class ActionBar extends Vue
{
    @Prop() public breakpoint: boolean;
    @Prop({ default: false }) public autoHeader: boolean;
    @Prop({ default: false }) public toggleFooter: boolean;
    @Prop({ default: false }) public toggleHeader: boolean;
    @Prop() public headerTitle: string;
    @Prop() public headerIcon: string;

    public dropdown: boolean = false;
    public floating: boolean = false;

    public created(): void
    {
        this.onBreakpointChanged(this.condition);
    }

    public get condition(): boolean
    {
        if (this.breakpoint !== undefined)
            return this.breakpoint;
        else
            return this.mobile;
    }

    public all(): any[]
    {
        return this.$slots.default().filter((p: any) => p.type.name == 'action-button');
    }

    public toolbar(): any[]
    {
        return this.all().filter((p: any) => p.props.toolbar !== undefined && p.props.toolbar !== false);
    }

    public firstToolbar(): any[]
    {
        const toolbar = this.toolbar();

        return toolbar.length > 0 ? [toolbar[0]] : [];
    }

    public otherToolbar(): any[]
    {
        return this.toolbar().slice(1);
    }

    public firstToolbarClass(): string
    {
        return `btn btn-${this.firstToolbar()[0].props.variant}`;
    }

    public back(): any[]
    {
        return this.all().filter((p: any) => p.props.back !== undefined && p.props.back !== false);
    }

    public firstBack(): any[]
    {
        const back = this.back();

        return back.length > 0 ? [back[0]] : [];
    }

    public other(): any[]
    {
        // const toolbar = this.toolbar();
        // const back = this.firstBack();

        // return this.all().filter(p => !toolbar.includes(p) && !back.includes(p));
        return this.all().filter(p => (p.props.toolbar === undefined || p.props.toolbar === false) && (p.props.back === undefined || p.props.back === false));
    }

    public get card(): Vue|null
    {
        let parent = this.$parent;

        while (parent)
        {
            if (parent.$options.name == 'data-card')
            {
                return parent;
            }

            parent = parent.$parent;
        }

        return null;
    }

    @Watch('condition')
    public onBreakpointChanged(value: boolean): void
    {
        if ((this.toggleHeader || this.toggleFooter) && this.card)
        {
            const card = this.card as any;

            if (this.toggleHeader)
            {
                if (value == false)
                    card.showHeader();
                else
                    card.hideHeader();
            }

            if (this.toggleFooter)
            {
                if (value == false)
                    card.showFooter();
                else
                    card.hideFooter();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.floating {
    position: fixed;
    right: 15px;
    bottom: 10px;
    z-index: 10002;

    &.dropup {
        .dropdown-menu {
            top: auto;
            bottom: 100%;
            margin-top: 0;
            margin-bottom: 0.125rem;
        }
    }
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

.action-bar {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    min-width: var(--ideo-app-min-width);
    height: calc(var(--ideo-header-height));
    padding: 10px 0;
    background-color: var(--bs-white);
    border-top: none;
    z-index: 99;

    .dropdown-menu {
        z-index: 9999;
    }

    .dropdown-toggle-split {
        &::before {
            box-shadow: none !important;
        }
    }
}

[data-bs-theme="dark"] {
    .action-bar {
        background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
    }
}

</style>
