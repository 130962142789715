import { defineAsyncComponent, Plugin } from 'vue';
import PortalVue from 'portal-vue';
import IdeoPlugin from '@/components/ideo';
import AsistonPlugin from '@/components/asistonCommon';
import DynamicFormsPlugin from '@/components/forms';

import ActionBar from '@/components/action/ActionBar.vue';
import ActionButton from '@/components/action/ActionButton.vue';
import ActionHeader from '@/components/action/ActionHeader.vue';
import ActionIcon from '@/components/action/ActionIcon.vue';
import ListView from '@/components/list/ListView.vue';
import ListViewSettings from '@/components/list/ListViewSettings.vue';
import ListViewHeader from '@/components/list/properties/ListViewHeader.vue';
import ListViewImage from '@/components/list/properties/ListViewImage.vue';
import ListViewLabel from '@/components/list/properties/ListViewLabel.vue';
import ListViewItem from '@/components/list/properties/ListViewItem.vue';
import ListViewButtons from '@/components/list/properties/ListViewButtons.vue';
import ListViewButton from '@/components/list/properties/ListViewButton.vue';
import ListViewCheckbox from '@/components/list/properties/ListViewCheckbox.vue';
import Confirmation from '@/components/common/Confirmation.vue';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';

const ComponentsPlugin: Plugin =
{
    async install(app, options)
    {
        // Ideo Bootstrap Components
        app.use(IdeoPlugin);

        app.use(AsistonPlugin);

        // Custom components
        app.component('ActionBar', ActionBar);
        app.component('ActionButton', ActionButton);
        app.component('ActionHeader', ActionHeader);
        app.component('ActionIcon', ActionIcon);
        app.component('ListView', ListView);
        app.component('ListViewSettings', ListViewSettings);
        app.component('ListViewHeader', ListViewHeader);
        app.component('ListViewImage', ListViewImage);
        app.component('ListViewLabel', ListViewLabel);
        app.component('ListViewItem', ListViewItem);
        app.component('ListViewButtons', ListViewButtons);
        app.component('ListViewButton', ListViewButton);
        app.component('ListViewCheckbox', ListViewCheckbox);
        app.component('Confirmation', Confirmation);
        app.component('ConfirmationModal', ConfirmationModal);

        // Custom async components
        app.component('Editor', defineAsyncComponent(() => import('@/components/common/Editor.vue')));
        app.component('Filepicker', defineAsyncComponent(() => import('@/components/common/FilePicker.vue')));
        app.component('Fileupload', defineAsyncComponent(() => import('@/components/common/FileUpload.vue')));
        app.component('Pagination', defineAsyncComponent(() => import('@/components/common/Pagination.vue')));
        app.component('PaginationInfo', defineAsyncComponent(() => import('@/components/common/PaginationInfo.vue')));
        app.component('PaginationBar', defineAsyncComponent(() => import('@/components/common/PaginationBar.vue')));
        app.component('Responsive', defineAsyncComponent(() => import('@/components/common/Responsive.vue')));
        app.component('Sort', defineAsyncComponent(() => import('@/components/common/Sort.vue')));
        app.component('DataCard', defineAsyncComponent(() => import('@/components/data/DataCardNew.vue')));
        app.component('DataSize', defineAsyncComponent(() => import('@/components/data/DataSize.vue')));
        app.component('DataSorting', defineAsyncComponent(() => import('@/components/data/DataSorting.vue')));
        app.component('DataExporting', defineAsyncComponent(() => import('@/components/data/DataExporting.vue')));
        app.component('Placeholder', defineAsyncComponent(() => import('@/components/placeholders/Placeholder.vue')));
        app.component('PlaceholderForm', defineAsyncComponent(() => import('@/components/placeholders/Form.vue')));
        app.component('PlaceholderGrid', defineAsyncComponent(() => import('@/components/placeholders/Grid.vue')));
        app.component('Filemanager', defineAsyncComponent(() => import('@/components/filemanager/FileManager.vue')));

        // Filters
        app.component('IdeoInlineFilters', defineAsyncComponent(() => import('@/components/filters/IdeoInlineFilters.vue')));
        app.component('IdeoFilter', defineAsyncComponent(() => import('@/components/filters/IdeoFilter.vue')));
        app.component('IdeoFilterText', defineAsyncComponent(() => import('@/components/filters/types/IdeoFilterText.vue')));
        app.component('IdeoFilterSelect', defineAsyncComponent(() => import('@/components/filters/types/IdeoFilterSelect.vue')));
        app.component('IdeoFilterMultiple', defineAsyncComponent(() => import('@/components/filters/types/IdeoFilterMultiple.vue')));
        app.component('IdeoFilterDatetime', defineAsyncComponent(() => import('@/components/filters/types/IdeoFilterDatetime.vue')));
        app.component('IdeoFilterCheckbox', defineAsyncComponent(() => import('@/components/filters/types/IdeoFilterCheckbox.vue')));
        app.component('CustomInlineFilter', defineAsyncComponent(() => import('@/components/filters/inline/CustomInlineFilter.vue')));
        // app.component('IdeoInlineFilters', defineAsyncComponent(() => import('@/components/filters/inline/IdeoInlineFilters.vue')));
        // app.component('IdeoFilter', defineAsyncComponent(() => import('@/components/filters/inline/IdeoFilter.vue')));

        // WMS
        app.component('LocalizationsBuildingsPicker', defineAsyncComponent(() => import('@/components/pickers/LocalizationsBuildingsPicker.vue')));
        app.component('LocalizationsZoneTypesPicker', defineAsyncComponent(() => import('@/components/pickers/LocalizationsZoneTypesPicker.vue')));
        app.component('WarehousesPicker', defineAsyncComponent(() => import('@/components/pickers/warehouses/WarehousesPicker.vue')));
        app.component('ProductsPicker', defineAsyncComponent(() => import('@/components/pickers/products/ProductsPicker.vue')));
        app.component('PrintersPicker', defineAsyncComponent(() => import('@/components/pickers/printers/PrintersPicker.vue')));
        app.component('ProductGroupsPicker', defineAsyncComponent(() => import('@/components/pickers/products/ProductGroupsPicker.vue')));
        app.component('LocalizationPicker', defineAsyncComponent(() => import('@/components/pickers/localization/LocalizationPicker.vue')));
        app.component('CarriersTypesPicker', defineAsyncComponent(() => import('@/components/pickers/carriers/CarriersTypesPicker.vue')));
        app.component('CarriersKindsPicker', defineAsyncComponent(() => import('@/components/pickers/carriers/CarriersKindsPicker.vue')));
        app.component('ContractorsPicker', defineAsyncComponent(() => import('@/components/pickers/contractors/ContractorsPicker.vue')));
        app.component('ContractorAliasesPicker', defineAsyncComponent(() => import('@/components/pickers/contractors/ContractorAliasesPicker.vue')));
        app.component('DeliveryMethodsPicker', defineAsyncComponent(() => import('@/components/pickers/deliveryMethods/DeliveryMethodsPicker.vue')));
        app.component('DocumentsStatusPicker', defineAsyncComponent(() => import('@/components/pickers/documentsStatus/DocumentsStatusPicker.vue')));
        app.component('ProductsFeaturesPicker', defineAsyncComponent(() => import('@/components/pickers/productsFeatures/ProductsFeaturesPicker.vue')));
        app.component('ContractorsFeaturesPicker', defineAsyncComponent(() => import('@/components/pickers/contractorsFeatures/ContractorsFeaturesPicker.vue')));
        app.component('ZonesPicker', defineAsyncComponent(() => import('@/components/pickers/zones/ZonesPicker.vue')));
        app.component('UsersPicker', defineAsyncComponent(() => import('@/components/pickers/users/UsersPicker.vue')));
        app.component('CameraBarcodeScannerModal', defineAsyncComponent(() => import('@/modules/wms/common/components/modals/CameraBarcodeScannerModal.vue')));
        app.component('BatchesPicker', defineAsyncComponent(() => import('@/components/pickers/batches/BatchesPicker.vue')));
        app.component('InventoriesTypePicker', defineAsyncComponent(() => import('@/components/pickers/inventoriesType/InventoriesTypePicker.vue')));
        app.component('DocumentStatus', defineAsyncComponent(() => import('@/modules/wms/common/components/elements/DocumentStatus.vue')));
        app.component('QueuesPicker', defineAsyncComponent(() => import('@/components/pickers/queues/QueuesPicker.vue')));
        app.component('RealizingDocumentTypesPicker', defineAsyncComponent(() => import('@/components/pickers/realizingDocumentTypes/RealizingDocumentTypesPicker.vue')));
        app.component('CouriersPicker', defineAsyncComponent(() => import('@/components/pickers/couriers/CouriersPicker.vue')));
        app.component('PackageTypesPicker', defineAsyncComponent(() => import('@/components/pickers/packageTypes/PackageTypesPicker.vue')));
        app.component('NumericKeyboard', defineAsyncComponent(() => import('@/modules/wms/common/components/elements/NumericKeyboard.vue')));
        app.component('PrintTemplateModal', defineAsyncComponent(() => import('@/modules/wms/configuration/printers/components/PrintTemplate.vue')));
        app.component('OwnFieldsDefinitionPicker', defineAsyncComponent(() => import('@/components/pickers/ownFields/OwnFieldsDefinitionPicker.vue')));
        app.component('PermissionsRolesPicker', defineAsyncComponent(() => import('@/components/pickers/permissionsRoles/PermissionsRolesPicker.vue')));

        // Formularze dynamiczne
        app.use(DynamicFormsPlugin);

        // External components
        app.use(PortalVue);
    }
};

export default ComponentsPlugin;
